@import './colors';

.accordion-button {
  flex-direction: row-reverse;
  background-color: white;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}
.accordion-button:focus {
  box-shadow: none;
  outline: none;
}

.accordion.secondary .accordion-item .accordion-header .accordion-button {
  flex-direction: row;
}

.accordion-item {
  border-top: none;
  border-left: none;
  border-right: none;
}

.accordion-button::after {
  background-position-y: center;
}

.accordion-button:not(.collapsed) {
  background-color: $primary-blue-6;
  color: $text-1;
  font-weight: bold;
}

.accordion-button.collapsed::after {
  background-image: url('../img/collapsed.svg');
}

.accordion-button:not(.collapsed)::after {
  background-image: url('../img/expanded.svg');
}

.secondary {
  > * {
    border: none !important;
  }

  .accordion-button {
    box-shadow: none;
  }

  .accordion-body {
    padding-left: 1em;
    padding-top: 0;
  }

  .nav-item {
    font-size: 1em !important;
  }
}

.accordion.secondary {
  .accordion-item {
    h2 {
      button {
        line-height: 1.5;
        &:focus-visible {
          border: 2px solid #0a58ca;
        }
      }
    }
  }
}
