// #region Typography Headings
@import './colors.scss';

body {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1 {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  line-height: 1.167;
  font-size: 40px;
  letter-spacing: 0.01em;
}

h2 {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  line-height: 1.2;
  font-size: 33px;
  letter-spacing: 0.025em;
}

h3 {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  line-height: 1.167;
  font-size: 28px;
  letter-spacing: 0.02em;
}

h4 {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  line-height: 1.235;
  font-size: 23px;
  letter-spacing: 0.02em;
}

h5 {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  line-height: 1.334;
  font-size: 19px;
  letter-spacing: 0.02em;
}

h6 {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  line-height: 1.6;
  font-size: 16px;
  letter-spacing: 0.03em;
}

// #endregion Typography Headings

//#region Typography

.text-xl {
  font-size: 21px;
}

.text-large {
  font-size: 18px;
}

.text-regular {
  font-size: 16px;
}

.text-small {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}

.text-tag-large {
  font-weight: normal;
  line-height: 15px;
  font-size: 16px;
  letter-spacing: 0em;
  text-transform: uppercase;
}

.text-tag-small {
  font-weight: normal;
  line-height: 15px;
  font-size: 12px;
  letter-spacing: 0em;
  text-transform: uppercase;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.no-wrap-white-space {
  white-space: nowrap;
}

.pills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.pill-label {
  font-size: 1.125em;
  font-weight: 700;
  white-space: nowrap;
}

.pill-small {
  background-color: #e2e2e2;
  border-radius: 1em;
  font-size: 0.875em;
  font-weight: 600;
  padding: 0.125em 0.75em;
  white-space: nowrap;
}

//#endregion Typography
