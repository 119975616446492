@import '../../../css/_colors';

.Container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-width: 20rem;
}

.Card {
  margin-bottom: 32px;
  width: 20rem;
}

.Link {
  color: $primary-purple-1;
  text-decoration: underline;
  font-style: italic;
  font-weight: 600;
}
.Link:hover,
.Link:focus {
  color: $primary-purple-2;
  text-decoration: none;
}

.Header {
  background-color: $primary-purple-1;
  color: #fff !important;
  padding-top: 16px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: none !important;
}

.CardButtonContainer {
  display: flex;
  justify-content: center;

  .CardButton {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.CaseCard {
  flex: 1;
  margin: 1em;

  .CaseTitle {
    height: 5em;
    display: flex;
    > div {
      margin: auto 0;
    }
  }

  .CardText {
    text-align: justify;
    margin-bottom: 2em;
  }
}
