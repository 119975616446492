@import '../../variables';
@import '../../css/colors';

.Landing {
  .Toolkit {
    .Panel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1em;

      .ImageContainer {
        flex: 25%;
        text-align: center;
      }

      .TextContainer {
        flex: 70%;
      }
    }
  }

  .Webinars {
    display: flex;
    flex-direction: column;

    .Panel {
      display: flex;
      flex-direction: column;

      .TopContainer {
        text-align: center;
        margin-bottom: 1em;
      }

      .BottomContainer {
        display: flex;
        flex-direction: row;
        @media (max-width: 990px) {
          flex-direction: column;
        }
        .ImageContainer {
          display: flex;
          > div {
            margin: 0 auto;
          }
        }

        .TextContainer {
          > div {
            flex-grow: 1;
          }

          label {
            font-weight: 700;
            font-size: 16px;
            line-height: 14px;
            color: $text-2;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-bottom: 1em;
          }

          flex: 1;
          display: flex;
          flex-direction: column;
          background-color: white;
          border-top: 5px solid $primary-yellow-1;
          border-right: 1px solid $borderaccent-5;
          border-left: 1px solid $borderaccent-5;
          border-bottom: 1px solid $borderaccent-5;
          box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.11);
          padding: 40px;
          @media (min-width: 1200px) {
            margin-top: 2em;
            left: -2em;
            position: relative;
          }
        }
      }
    }
  }

  .CaseStudies {
    .TopContainer {
      text-align: center;
    }

    .StudiesContainer {
      display: flex;
      flex-direction: row;
      gap: 1em;
      flex-wrap: wrap;
      > div {
        flex: 32%;
        min-width: 400px;
      }
    }
  }

  .ResourcesContainer {
    .TextContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      text-align: center;

      > div {
        flex: 1;
        margin: 1em;
        > div {
          margin: auto;
          max-width: 350px;
          > * {
            margin-bottom: 1em;
          }
        }
      }
    }
  }
}

.CaseStudies {
  .FeaturedCaseContainer {
    display: flex;
    flex-wrap: wrap;
    > div {
      flex: 1;
    }
  }

  .AllCaseContainer {
    display: flex;
    flex-direction: row;
    > div {
      flex: 1;
    }

    .AllCaseInnerContainer {
      display: flex;
      flex-direction: column;
    }
  }
}

.Toolkits {
  .Toolkit {
    .Panel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .ImageContainer {
        flex: 25%;
        text-align: center;
        display: flex;
        > div {
          margin: auto;
        }
      }

      .TextContainer {
        flex: 70%;
      }
    }
  }

  .Resources {
    .MainContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4%;

      .Improvements {
        flex: 65%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .Improvement {
          padding: 1em 1em;
          min-width: 200px;
          max-width: 350px;
          margin: auto;

          h3 {
            background-color: $primary-purple-2;
            display: inline-block;
            border-radius: 50%;
            height: 49px;
            width: 49px;
            display: flex;
            color: white;
            > span {
              margin: auto;
            }
          }
        }
      }

      .OtherImprovements {
        flex: 30%;
        min-width: 300px;
        background-color: $borderaccent-6;
        padding: 2em;

        h6,
        ul > li {
          margin-bottom: 2em;
        }
      }
    }
  }

  .Tool {
    .Panel {
      .Container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5%;
        margin-top: 2em;

        .Left {
          flex: 24%;
          text-align: center;
          padding: 1em;
        }

        .Right {
          margin: auto;
          flex: 70%;
          padding: 1em;
        }
      }
    }
  }
}

.blueBox {
  background-color: $primary-blue-4;
  padding: 16px;
}

.codetable tbody tr td {
  padding: 20px;
}

.toolKit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
}
.leftToolKit {
  width: 30%;
  margin: 1.5rem auto 1.5rem auto;
  text-align: right;
}
.rightToolKit {
  width: 70%;
  margin: 1.5rem 0 1.5rem 1.5rem;
  padding: 20px;
}
.rightToolKit p {
  width: 588px;
  @media (max-width: 1200px) {
    width: 316px;
    height: 144px;
  }
}

.webinars {
  background-color: $primary-blue-6;
  text-align: center;
  padding: 30px;
}

.webinars p {
  width: 820px;
  height: 84px;
  margin: 0 auto;
}

.webinars img {
  width: 486.41px;
  height: 403.78px;

  @media (max-width: 1200px) {
    width: 323.87px;
    height: 268.85px;
  }
}
.webinarsContainer {
  background-color: white;
  min-height: 376px;
  border-top: 13px solid $primary-yellow-1;
  border-right: 1px solid $borderaccent-5;
  border-left: 1px solid $borderaccent-5;
  border-bottom: 1px solid $borderaccent-5;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.11);
  padding: 40px;
  text-align: left;
  @media (min-width: 1200px) {
    margin-top: 55px;
    left: -80px;
    position: relative;
    width: 533px;
  }
  @media (max-width: 1200px) {
    width: 321px;
    height: 340px;
  }
}

.webinarsContainer p {
  width: 422px;
  height: 96px;
  margin-left: 0;
  @media (max-width: 1200px) {
    width: 290px;
    min-height: 89px;
    margin-bottom: 50px;
  }
}

.caseStudy {
  border: 1px solid $borderaccent-6;
  border-radius: 4px;
  max-width: 316px;
  margin: 50px;
  padding: 0;
  text-align: left;
}
.caseStudy p {
  width: 268px;
  height: 220px;
  margin: 0 auto;
}
.caseStudy h4 {
  width: 295px;
  margin: 0 auto;
}
.resourcesContainer {
  padding: 20px;
}
.resourcesContainer p {
  margin: 0 auto;
  width: 320px;
  height: 144px;
}
.puplication,
.puplicationContnet {
  width: 80%;
  margin: auto;
  border-bottom: 1px solid $borderaccent-2;
  padding-bottom: 50px;
}
.puplicationContainer {
  text-align: left;
}
.puplicationContainer p {
  width: 80%;
  //margin:  auto;
  padding-top: 30px;
}
.caseTitle {
  min-height: 48px;
}

.Webinars {
  .WebinarContainer {
    border-top: 1px solid $borderaccent-2;

    > div {
      margin: 2em 0;

      h3,
      h5 {
        margin-bottom: 1rem;
      }
    }
  }
}

.Presentations {
  .PresentationContainer {
    border-top: 1px solid $borderaccent-2;

    > div {
      margin: 2em 0;
    }
  }
  .PresentationContainer p {
    width: 83%;
  }
}

.Publications {
  .PublicationPDFs {
    border-top: 1px solid $borderaccent-2;
    margin: 2em 0;
    padding: 2em 0;
    text-align: center;
  }
  .PublicationContainer {
    border-top: 1px solid $borderaccent-2;

    > div {
      margin: 2em 0;

      .IndicatorsGroup {
        display: flex;
        flex-wrap: wrap;

        .IndicatorsHeading {
          font-weight: bold;
        }

        .IndicatorsTag {
          display: flex;
          margin-left: 12px;
          padding: 0 12px;
          font-size: 14px;
          align-items: center;
          background-color: #f5f5f5;
          color: $text-1;
          font-weight: bold;
          width: auto;
          border-radius: 20px;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
        }
      }
    }
  }
}
.splitScreen {
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.leftPane {
  @media (min-width: 1200px) {
    width: 60%;
  }
}

.rightPane {
  @media (min-width: 1200px) {
    width: 40%;
  }
}
.improvment {
  text-align: left;
}

.otherImprovment {
  background-color: $borderaccent-4;
  width: 350px;
  padding: 30px;
  text-align: left;
  @media (max-width: 1200px) {
    width: 100%;
  }
}
