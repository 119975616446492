@import './colors';

main .container {
  @media (max-width: 575px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.outerContainer {
  margin: 0px auto;
  padding: 32px;
  @media (max-width: 600px) {
    padding: 16px;
  }
}

.outer-container {
  margin: 0px auto;
  padding: 32px;
  @media (max-width: 600px) {
    padding: 16px;
  }
}

.container-padding {
  padding: 16px !important;
}

.flex {
  display: flex !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grid-container {
  display: grid;
}

.grid-container-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-container-gap-2 {
  gap: 2rem;
}

.grid-container-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 992px) {
  .grid-container {
    grid-template-columns: auto;
  }
}

.vertical-tabs-container {
  display: flex;
  background-color: white;

  .vertical-tabs {
    border-right: 1px solid $borderaccent-2;
    font-size: 1.2em;
  }

  .vertical-tabs-nav {
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }

  .vertical-tabs-nav-item {
    align-items: center;
    margin: 10px 0px 10px 0px;
    > a {
      border-radius: 0;
      margin: 0;
      padding: 0;
    }
  }

  .vertical-tabs-nav .nav-link.active,
  .vertical-tabs-nav .show > .nav-link {
    padding-left: 20px;
    display: flex;
    align-items: center;
    border-left: 7px solid $primary-purple-1;
    color: $primary-purple-1;
    font-weight: 700;
    margin-left: -27px;
    background-color: white;
  }
}

@media (min-width: 1201px) {
  .vertical-tabs-container {
    .sidebar-column {
      flex-shrink: 0;
      min-width: 15rem;
      max-width: 22rem;
      padding-top: 12px;
    }

    .content-column {
      flex-grow: 1;
    }
  }
}

.top-banner-container {
  display: flex;
  flex-direction: column;
  background-image: url('../img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $primary-purple-1;
  color: white;
  text-align: center;
  align-items: center;
  padding: 2em;

  @media (min-width: 800px) {
    min-height: 20em;
  }

  > div {
    margin: auto;
  }

  h1 {
    margin-bottom: 1em;
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin: auto;
    max-width: 800px;
  }
}

.top-banner-container-normal {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 2em;
  padding-bottom: 4em;

  @media (min-width: 800px) {
    min-height: 5em;
  }

  > div {
    margin: auto;
  }

  h1 {
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
    max-width: 1080px;
    margin: 2em auto 0;
  }
}

.small-container {
  max-width: 900px;
}
