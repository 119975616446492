@import '../../variables';
@import '../../css/colors';

.MessageContainer{
    width: 464px;
    height: 252.5px;
    padding: 50px 50px 50px 50px;
    background-image: url('../../img/messageBox.svg');
    p{
        font-style: italic;
    }
    strong{
        color: $Primary;
    }
}