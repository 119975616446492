$primary-blue-1: #00476B;
$primary-blue-2: #005B94;
$primary-blue-3: #196B9F;
$primary-blue-4: #E0EBF2;
$primary-blue-5: #EFF5F8;
$primary-blue-6: #F7FAFC;

$primary-purple-1: #691F74;
$primary-purple-2: #83378B;
$primary-purple-3: #B48FBA;
$primary-purple-4: #E8DDEA;

$primary-yellow-1: #FFBC00;
$primary-yellow-2: #FFDE80;
$primary-yellow-3: #FFF5DA;
$primary-yellow-4: #FFF8E6;

$secondary-red-1: #CF0021;
$secondary-red-2: #E78090;
$secondary-red-3: #F8DADF;
$secondary-red-4: #FAE6E9;

$secondary-green-1: #528141;
$secondary-green-2: #7EB266;
$secondary-green-3: #8CC671;
$secondary-green-4: #C6E2B8;
$secondary-green-5: #E2F1DB;

$text-1: #3D3D3D;
$text-2: #555555;
$text-3: #757575;
$text-4: #888888;
$text-5: #00000080;
$text-6 : #979292;

$body-1:#212529;

$borderaccent-1: #C6C6C6;
$borderaccent-2: #E2E2E2;
$borderaccent-3: #EFEFEF;
$borderaccent-4: #FAFAFA;
$borderaccent-5: #E7E5E5;
$borderaccent-6: #00000020;

$linkbutton-1: #0071BC;
$linkbutton-2: #205493;
