.AvailabilityDialog {
  > div {
    @media (min-width: 992px) {
      max-width: 940px;
    }
    @media (min-width: 1200px) {
      max-width: 1040px;
    }
    > div {
      border-radius: 1.25rem;
      overflow: hidden;
    }
  }
}
