.videoFrame
{
    width: 900px;
    height: 520px;
    margin: auto;
    @media (max-width: 1200px) {
        width: 100%;
        
    }
}
.videoFrame button
{
    float: right;
}

.videoFrame iframe
{
    width: 854px;
    height:480px;
    @media (max-width: 1200px) {
        width: 100%;
    }
}

