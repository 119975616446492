@import './_colors';

button {
  text-transform: none !important;
  border: none;
  border-radius: 8px;
  line-height: 1.8;

  &:hover {
    cursor: pointer;
  }

  &.large {
    min-height: 39px;
    border-radius: 10px;
    font-size: 14px;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  &.small {
    min-height: 29px;
    border-radius: 6px;
    font-size: 12px;
    padding: 3px 12px;
  }

  &.x-small {
    min-height: 23px;
    border-radius: 6px;
    font-size: 12px;
    padding: 3px 10px;
  }
}

button.primary {
  background-color: $linkbutton-1;
  color: white !important;

  @media only screen and (max-width: 767px) {
    width: 100% !important;
    margin-top: 20px;
  }
  &.arrow-right {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 14px center;
    padding-right: 32px !important;
  }

  &.arrow-down {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    padding-right: 24px !important;
  }

  &.arrow-left {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 14px center;
    padding-left: 32px !important;
  }

  &:hover {
    background-color: $linkbutton-2;
  }

  &:active {
    background-color: $linkbutton-2;
  }

  &:disabled {
    background-color: $borderaccent-2;
    border-color: $borderaccent-1;
    color: $text-4 !important;

    &.arrow-right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23888'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 14px center;
      padding-right: 32px !important;
    }

    &.arrow-down {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%23888'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      padding-right: 24px !important;
    }

    &.arrow-left {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%23888'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left 14px center;
      padding-left: 32px !important;
    }
  }
}

button.transparent {
  background-color: rgba(0, 0, 0, 0) !important;
  color: $linkbutton-1;
  border: solid 1px $linkbutton-1;

  &.white {
    color: white;
    border: solid 1px white;
    &.arrow-right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23FFF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 14px center;
      padding-right: 32px !important;
    }
  }

  &.arrow-right {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%230071BC'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 14px center;
    padding-right: 32px !important;
  }

  &.share-left {
    background-image: url('../img/share.svg');
    background-repeat: no-repeat;
    background-position: left 14px center;
    padding-left: 32px !important;
  }

  &:hover {
    cursor: pointer;
    background-color: $linkbutton-2;

    &.arrow-right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23FFF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 14px center;
      padding-right: 32px !important;
    }
  }
  &.center {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateY(-50%);
  }
}

button.secondary {
  background-color: white;
  color: $linkbutton-1;
  border: solid 1px $linkbutton-1;

  &.arrow-right {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%230071BC'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 14px center;
    padding-right: 32px !important;
  }

  &.arrow-down {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%230071BC'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    padding-right: 24px !important;
  }

  &.arrow-left {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%230071BC'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 14px center;
    padding-left: 32px !important;
  }

  &:hover {
    background-color: $linkbutton-2;
    color: white;

    &.arrow-right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23FFF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 14px center;
      padding-right: 32px !important;
    }

    &.arrow-down {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%23FFF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      padding-right: 24px !important;
    }

    &.arrow-left {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%23FFF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left 14px center;
      padding-left: 32px !important;
    }
  }

  &:active {
    background-color: $primary-purple-2;
    border-color: $primary-purple-2;
  }

  &:disabled {
    background-color: $borderaccent-2;
    color: $text-4;
    border-color: $borderaccent-1;

    &.arrow-right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23888'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      padding-right: 24px !important;
      color: $text-4;
    }

    &.arrow-down {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%23888'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 14px center;
      padding-right: 32px !important;
      color: $text-4;
    }

    &.arrow-left {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%23888'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left 14px center;
      padding-left: 32px !important;
      color: $text-4;
    }
  }
}

.link-button.primary {
  color: white;
  background-color: $linkbutton-1;
  border-color: $linkbutton-1;
  border-radius: 10px;

  &.arrow-right {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 14px center;
    padding-right: 32px !important;
  }

  .arrow-down {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    padding-right: 24px !important;
  }

  &.arrow-left {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 14px center;
    padding-left: 32px !important;
  }

  &.small {
    min-height: 23px;
    border-radius: 6px;
    font-size: 14px;
    padding: 6px 4px;
    padding-right: 20px !important;
    background-position: right 6px center;
  }
}

.link-button.secondary {
  background-color: white;
  color: $linkbutton-1 !important;

  &.arrow-right {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23005B94'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 14px center;
    padding-right: 32px !important;
  }

  .arrow-down {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%23005B94'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    padding-right: 24px !important;
  }

  &.arrow-left {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%23005B94'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 14px center;
    padding-left: 32px !important;
  }
  &.borderless {
    border: none;
  }
}

.link-button.transparent {
  background-color: transparent;
  color: $linkbutton-1;
  border-color: $linkbutton-1;

  &.white {
    color: white !important;
    border-color: white !important;
  }

  &.arrow-right {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23005B94'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 14px center;
    padding-right: 32px !important;
    &.white {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23FFF'/%3E%3C/svg%3E");
    }
  }

  .arrow-down {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%23005B94'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    padding-right: 24px !important;
    &.white {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 10 6'%3E%3Cpath d='M4.61138 5.84086L0.160979 1.44079C-0.0536601 1.22858 -0.0536601 0.884535 0.160979 0.672347L0.680047 0.159152C0.89432 -0.0526961 1.24159 -0.0531035 1.45637 0.158247L5.00001 3.64538L8.54363 0.158247C8.75841 -0.0531032 9.10568 -0.0526957 9.31995 0.159153L9.83902 0.672348C10.0537 0.884558 10.0537 1.22861 9.83902 1.44079L5.38862 5.84084C5.174 6.05305 4.82602 6.05305 4.61138 5.84086Z' fill='%23FFF'/%3E%3C/svg%3E");
    }
  }

  &.arrow-left {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%23005B94'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 14px center;
    padding-left: 32px !important;
    &.white {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' transform='translate(-8.59 -6)' fill='%23FFF'/%3E%3C/svg%3E");
    }
  }
}

.search-share-btn {
  background-image: url('../img/share.svg');
  background-repeat: no-repeat;
  background-position: left 14px center;
  border: 1px solid $linkbutton-1;
  color: $linkbutton-1;
  padding-left: 32px !important;

  &:hover {
    background-color: #eff5f8;
    border: 1px solid $linkbutton-1;
    color: $linkbutton-1;
  }
}
