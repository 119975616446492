@import './_colors';

.color-white {
  color: #fff;
}

.color-primary-1 {
  color: $primary-purple-1;
}

.color-primary-2 {
  color: $primary-purple-2;
}
