@import './_colors';

ul.qiTabs {
  justify-content: center;
  margin-bottom: 2em;
  @media (max-width: 1200px) {
    align-items: center;
  }
}

ul.qiTabs li {
  margin-bottom: 0;
  > button {
    border-radius: 0 !important;
  }
}

ul.qiTabs li.nav-item button.nav-link,
ul.qiTabs li.nav-item button.nav-link:hover,
ul.qiTabs li.nav-item button.nav-link:focus,
ul.qiTabs li.nav-item button.nav-link:focus-visible {
  border-color: transparent;
  border-width: 0px 0px 5px 0px;
  font-weight: 700;
  padding-bottom: 3px;
  text-decoration: none;
}

ul.qiTabs li.nav-item button.nav-link.active {
  color: $primary-purple-1;
  background-color: transparent !important;
  border-bottom-color: $primary-yellow-1;
}
