@import '../../variables';
@import '../../css/_colors';

.Home {
  .HeroContainer {
    display: flex;
    color: #fff;
    min-height: 380px;
    background-image: url('../../img/home_banner.jpg');
    background-repeat: no-repeat;
    background-position: center;

    .HeroLeftContainer {
      background: linear-gradient(110deg, rgba($primary-blue-2, 0.8) 80%, transparent 80%);
      padding: 4em;
      padding-bottom: 140px; // so that the -120px GetToAHRQPanel dont overlap

      @media only screen and (max-width: 1200px) {
        background: rgba($primary-blue-2, 0.8);
      }

      .HeroLeftContainerText {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 42px;
        text-align: left;
        letter-spacing: 0.01em;
        width: 80%;
      }
    }

    .HeroRightContainer {
      flex: 1;
      min-width: 50%;
      background: linear-gradient(110deg, transparent 80%, rgba($primary-blue-2, 0.8) 80%);

      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
  }

  .GetToKnowAHRQ {
    background-color: $primary-blue-6;

    .GetToKnowAHRQContainer {
      position: relative;
      top: -50px;

      // z-index: 2;
    }
  }

  .ActionsContainerContainer {
    @media only screen and (min-width: 1200px) {
      padding: 60px 0;
    }

    .ActionsContainer {
      background: url('../../img/bg.png');
      background-size: 100% !important;
      background-position: center;
      background-color: $primary-purple-1;

      .Panel {
        display: flex;
        flex-direction: row;

        @media only screen and (min-width: 1200px) {
          padding: 0;
        }

        @media only screen and (max-width: 1200px) {
          flex-direction: column;
        }

        .ImageContainer {
          flex: 40%;
          text-align: center;
          display: flex;
          img {
            min-width: 360px;
            margin: auto;
            border-radius: 25px;

            @media only screen and (min-width: 1200px) {
              position: relative;
              height: calc(100% + 80px);
              top: -40px;
            }
          }
        }

        .TextContainer {
          flex: 60%;
          height: 80%;
          max-width: 600px;
          color: white;
          padding: 8% 1em;
          margin: auto;
          @media only screen and (max-width: 1200px) {
            padding: 1em 1em 0 1em;
            max-width: none;
          }

          .ItemContainer {
            ul {
              list-style: none;
              color: $primary-blue-2;
              font-weight: bold;
            }

            > div {
              display: flex;
              flex-wrap: wrap;
              gap: 1em;
            }
          }
        }
      }
    }
  }

  .ResourceNewsContainer {
    display: flex;
    gap: 1em;
    flex-direction: row;
    flex-wrap: wrap;

    .ResourceContainer {
      display: flex;
      margin: 0 auto;
      flex-direction: column;

      .Resources {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media (max-width: 600px) {
          grid-template-columns: auto;
        }

        .Resource {
          display: flex;
          background: $primary-blue-6;
          border-radius: 23px;
          margin: 1em;
          padding: 2em;
          height: 160px;

          > div {
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;

            text-align: center;

            img {
              margin-bottom: 1em;
              width: 60px;
            }

            a {
              font-weight: bold;
            }
          }
        }
      }
    }

    .NewsContainer {
      flex-grow: 1;
      .NewsList {
        display: flex;
        flex-direction: column;

        .News {
          display: flex;
          flex-direction: column;
          padding: 1em 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);

          > div {
            > div {
              margin: 0.3em 0;
            }
          }

          .NewsDate {
            color: $text-3;
            font-weight: bold;
          }

          .NewsTags {
            > span {
              border-radius: 10px;
              font-size: 12px;
              padding: 0.1em 1.5em;
              display: inline-block;
              background-color: $borderaccent-5;
              color: $text-1;
              width: auto;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
            }
          }
        }
      }
    }
  }
}

// Here

.actionDiv {
  padding-right: 12px;
  @media only screen and (max-width: 767px) {
    padding-right: 0;
    padding-top: 12px;
  }
}

.cardBorder {
  @media only screen and (max-width: 767px) {
    border-right: none;
  }
  color: black;
}

.cardLink {
  font-size: 16px;
  width: 177px;
  margin: 0 auto;
}

.cardBtn {
  margin: 16px;
}

.dark {
  padding: 24px 0;
  background-color: $Gray-Dark;
}

.heroLearnMore {
  float: left;
}

.heroRow {
  margin: 24px 0;
  color: white;
}

.heroRow h4 {
  max-width: 80%;
  margin: 16px auto;
}

.resourcesContainer {
  background-color: white;
  padding: 50px;
}
.resourcesDiv {
  width: 468px;
  margin: 0 auto;
}
.faIcons {
  color: $primary-purple-1;
  font-size: 50px;
}

.resourcesInner {
  box-sizing: border-box;
  width: 212px;
  min-height: 168px;
  background: $primary-blue-6;
  border-radius: 23px;
  text-align: center;
}

.resourcesInner div {
  height: 40px;
  margin: 0 auto;
  padding: 20px;
}

.stretchCard {
  display: flex;
  align-items: stretch;
}

.AboutUs {
  .LinksContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .CaseCard {
      flex: 20%;
      display: flex;
      margin: 1%;
      min-width: 260px;

      .Body {
        display: flex;
        flex-direction: column;

        .CaseTitle {
          display: flex;
          min-height: 2em;
          > div {
            margin: auto 0;
          }
        }

        > p {
          flex-grow: 1;
        }
      }

      .ResourcesCardButtonContainer {
        margin-top: auto;
      }
    }
  }
}

.New {
  color: $secondary-red-1;
  font-weight: bold;
}

.DescriptionColumn {
  display: flex;
  flex-direction: column;
}

.TileLink {
  color: $primary-purple-1;
  font-size: 15px;
  text-decoration: underline;
  font-style: italic;
  font-weight: 600;
  line-height: 1.1;
  margin-top: 0.75em;
}
.TileLink:hover,
.TileLink:focus {
  color: $primary-purple-2;
  text-decoration: none;
}
