@import '../../variables';
@import '../../css/_colors';

.ExpandToggleContainer {
  min-width: 100px;
}

.AccordionHeading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  align-items: center;
  font-weight: normal;
}

.AccordionHeadingMain {
  font-size: 18px;
  line-height: 1.2;
  padding-right: 1rem;
  font-weight: normal;
}

.AccordionHeadingTertiary {
  font-size: 14px;
}

.CopyContainer {
  display: flex;
  align-items: center;
  width: min-content;

  .CopiedText {
    margin-left: 4px;
    font-size: 14px;
    line-height: 1;
    color: $text-1;
  }
}

.CopyBtn,
.CopyBtn:hover {
  background-color: transparent;
  border-color: none;
}

.Search {
  margin: 0 auto;
  max-width: 800px;
  Button {
    margin-top: 0;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  > div {
    padding: 10px;
    @media (max-width: 768px) {
      padding: 5px;
    }
  }

  .InputGroupContainer {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    .InputGroup {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .SearchBox {
        height: 44px;
        width: 10px !important;
        border: 1px solid $borderaccent-1;
        border-radius: 5px;

        &:focus {
          box-shadow: none;
        }
      }

      .ResetSearch {
        position: relative;
        float: right;
        // width: 40px !important;
        text-align: center;
        width: auto !important;
      }
    }
  }
}

.SelectMenu {
  border-radius: 4px;
  max-width: 400px;
}

.JumpToUtility {
  display: flex;
  justify-content: flex-start;
  padding-right: 50px;

  .JumpToLabel {
    width: 60px;
  }

  .Ul {
    display: inline;
    font-size: 14px;
    color: $linkbutton-1;
  }

  .Ul:not(:last-child):after {
    content: '|';
    margin: 8px;
    color: $text-3;
  }
}

.CategoryAccordion {
  padding: 0;

  .CategoryAccordionHeader {
    button {
      border-radius: 0;
      margin: 0;
      padding: 0;
      background-color: white !important;
      color: $primary-blue-3 !important;
      font-size: 1.2rem;
      border: none;
      font-weight: normal;
    }
  }
}

.CategoryAccordionHeader button::after {
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.CategoryAccordionHeader div div {
  padding: 0;
}

.CategoryAccordionHeader div h2 button.accordion-button:not(.collapsed) {
  padding: 20px !important;
}
