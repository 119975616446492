@import '../../css/colors';

.DownloadSpecBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
}

.MeasuresHome {
  .QIResourcesContainer {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    flex-wrap: wrap;

    margin: 3rem 0;

    .CaseCard {
      flex: 30%;
      min-width: 300px;
      display: flex;

      .Body {
        display: flex;
        flex-direction: column;

        .CaseTitle {
          height: 2em;
          display: flex;
          > div {
            margin: auto 0;
          }
        }

        > p {
          flex-grow: 1;
        }
      }

      .ResourcesCardButtonContainer {
        margin-top: auto;
      }
    }
  }
}

.MeasuresSelect {
  width: 160px !important;
  height: 100%;
}

.NQF {
  .NqfTable {
    .TableHeading {
      font-weight: 700;
      background-color: $primary-blue-2;
      color: white;
      th {
        text-align: center;
        vertical-align: middle;
      }
    }

    .TableSecondaryHeading {
      font-weight: 700;
      background-color: $primary-blue-1;
      color: white;
      th {
        vertical-align: middle;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    .Danger {
      color: $secondary-red-3;
    }

    td {
      vertical-align: middle;
    }
  }
}

.MeasuresPhases {
  min-height: 160px;

  > div {
    > h2 {
      text-align: center;
    }

    > div {
      display: flex;
      flex-direction: column;
      > ul {
        margin: auto;
      }
    }
  }
}

