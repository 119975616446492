@import './_colors';

//#region Align

.align-center {
  text-align: center;
  align-items: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-justify {
  text-align: justify;
}

//#endregion Align

//region Height / Width

.height-auto {
  height: auto !important;
}

.width-auto {
  width: auto !important;
}

.height-full {
  height: 100% !important;
}

.width-full {
  width: 100% !important;
}

.height-max-full {
  max-height: 100% !important;
}

.width-max-full {
  max-width: 100% !important;
}

//endregion Height / Width

.bg-primary {
  background-image: url('../img/bg.png');
  background-size: cover;
}

.bg-primary-blue-5 {
  background-color: $primary-blue-5;
}

.bg-primary-blue-6 {
  background-color: $primary-blue-6;
}

// types

.ahrq-alert {
  background: $primary-yellow-2;
  color: $text-1;

  .container {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    div {
      padding-right: 1rem;
      flex-grow: 1;
      text-align: center !important;
    }
  }
}

.border-bottom {
  border-bottom: 1px solid $borderaccent-2;
}

.list-style-none {
  list-style-type: none;
}

ul.clean {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Select */

.ahrq-simple-select__option,
.ahrq-simple-select__single-value {
  white-space: pre-wrap !important;
}

.ahrq-simple-select__control {
  height: 100%;
  width: 100%;
}

/* End Select */

/* Top Header */

.top-header {
  background-color: $borderaccent-3;

  h6 {
    margin-bottom: 0;

    a {
      // color: $primary-blue-3;
      // font-size: 14px;

      color: $linkbutton-1;
      font-size: 14px;
      font-weight: normal;
      display: inline-block;
      padding: 3px 0;
    }
  }

  div {
    align-items: center;
    display: flex;

    img {
      width: 16px;
      margin-right: 8px;
    }
  }
}

.header-top-section {
  display: flex;
  flex-wrap: wrap;
  margin: 0.3em auto;
  gap: 20px;

  .header-logo-container {
    display: flex;
    flex: 1;
    .header-logo {
      margin: auto auto auto 0;
      img {
        min-width: 110px;
        max-width: 200px;
      }
    }
  }

  .header-link-container {
    display: flex;
    min-width: 350px;

    .utility-nav {
      margin: auto 0 auto auto;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: inline;
          font-size: 14px;

          img {
            width: 15px;
            margin-right: 4px;
          }

          a {
            color: $text-1;
            text-decoration: none;
          }

          a:hover {
            color: $primary-blue-3;
            text-decoration: underline;
          }
        }

        li::after {
          content: '  ';
          margin: 8px;
        }

        li:last-child::after {
          content: '';
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }
  }
}

.utility-site {
  background-color: $borderaccent-3;
  ul,
  li {
    font-size: 14px;
    height: 26px;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }

  ul {
    display: flex;
    li {
      a {
        color: $primary-blue-2;

        display: inline-block;
        padding: 3px 0;
      }
    }
    li:not(:last-child):after {
      content: '|';
      color: $text-1;

      padding: 0 8px;
    }
  }
}

.navbar-main {
  padding: 0;
}

.navbar-main-phone {
  .navbar-toggler {
    padding: 0;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .navbar-main-phone-brand-container {
    display: flex;
    flex: 1 1 0;
    width: 100%;
    padding: 5px 10px;

    > .navbar-brand {
      flex: 1;
      display: flex;
      margin: 0;
      margin-right: 1rem;
    }
  }

  .navbar-main-phone-search-container {
    flex: 1 1 0;
    width: 100%;
    padding: 5px 10px;
  }
}

.navbar-main-large {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .navbar-brand {
    display: flex;
    align-items: center;
    a {
      display: block;
    }
  }
}

.mobile-dropdown-nav-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mobile-dropdown-nav-items-item {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/* End Top Header */

.accordion-body .faq-table td {
  vertical-align: top;
}

.sub-tag {
  height: 23px;
  margin: 10px 0;
  padding: 0px 13px 0px 13px;
  gap: 10px;
  border-radius: 30px 30px 30px 30px;
  background: $borderaccent-2;
}
