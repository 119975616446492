$h1FontSize: 40px;
$h2FontSize: 30px;
$h3FontSize: 24px;
$h4FontSize: 20px;
$h5FontSize: 15px;
$h6FontSize: 13px;
$LeadFontSize: 20px;
$BodyFontSize: 17px;
$Display1FontSize: 52px;
$Display2FontSize: 44px;
$MainBackGroundColor: #ffffff;
$Blue: #005b94;
$Blue-Lighter: #408fbf;
$Blue-Dark: #00476b;
$Purple: #691f74;
$Yellow-Light: #ffd971;
$Yellow: #ffc425;
$Yellow-alert: #ffde80;
$Primary: #0071bc;
$Primary-Darker: #205493;
$Primary-Darkest: #112e51;
$Base: #212121;
$Gray-Dark: #323a45;
$Gray-Light: #aeb0b5;
$Primary-Alt: #02bfe7;
$Primary-Alt-Lightest: #e1f3f8;
$Primary-Alt-Light: #9bdaf1;
$Primary-Alt-Dark: #00a6d2;
$Primary-Alt-Darkest: #046b99;
$Secondary: #e31c3d;
$Secondary-Darkest: #981b1e;
$Secondary-Dark: #cd2026;
$Secondary-Light: #e59393;
$Secondary-Lightest: #f9dede;
$Gold: #fdb81e;
$Gold-Light: #f9c642;
$Gold-Lighter: #fad980;
$Gold-Lightest: #fff1d2;
$Green: #2e8540;
$Green-Light: #4aa564;
$Green-Lighter: #94bfa2;
$Green-Lightest: #e7f4e4;
$Gray: #5b616b;
$Gray-Light: #aeb0b5;
$Gray-Lighter: #d6d7d9;
$Gray-Lightest: #f1f1f1;
$Gray-Warm-Dark: #494440;
$Gray-Warm-Light: #e4e2e0;
$Primary-Darkest: #112e51;
$Gray-Cool-Light: #dce4ef;
$Visited: #4c2c92;
$PrimaryFont: 'Public Sans', sans-serif;
$SecondaryFont: 'Source Sans Pro', sans-serif;

:export {
  h1FontSize: $h1FontSize;
  h2FontSize: $h2FontSize;
  h3FontSize: $h3FontSize;
  h4FontSize: $h4FontSize;
  h5FontSize: $h5FontSize;
  h6FontSize: $h6FontSize;
  LeadFontSize: $LeadFontSize;
  BodyFontSize: $BodyFontSize;
  Display1FontSize: $Display1FontSize;
  Display2FontSize: $Display2FontSize;
  Blue: $Blue;
  Blue-Lighter: $Blue-Lighter;
  Blue-Dark: $Blue-Dark;
  Purple: $Purple;
  Yellow-Light: $Yellow-Light;
  Yellow: $Yellow;
  PrimaryFont: $PrimaryFont;
  SecondaryFont: $SecondaryFont;
}
