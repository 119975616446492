@import '../../css/colors';

.Landings {
  .LandingsSection {
    .LandingsCard {
      max-width: 32rem;
      padding: 2em;
      margin: auto;
    }
  }
}

.resourcesContainer {
  padding: 20px;
}
.resourcesContainer p {
  margin: 0 auto;
  width: 320px;
  height: 144px;
}
.resourcesContainer a {
  color: $linkbutton-1;
}
