@import './colors';

.footer-1 {
  background-color: $primary-blue-2;
  color: white;
  padding: 24px 32px;
  border-bottom: 1px solid white;

  .items-container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 2em;
    flex-wrap: wrap;

    a {
      color: white;
    }

    > div {
      flex-grow: 1;
    }
  }
}

.footer-2 {
  background-color: $primary-blue-2;
  color: white;
  padding: 24px 32px;

  .items-container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      color: white;
    }

    > div {
      @media (min-width: 992px) {
        width: 33%;
      }
      flex-grow: 1;
      padding: 1em;
    }

    .email-signup {
      display: flex;
      flex-direction: row;
      gap: 1em;

      .signup-btn {
        button {
          background-color: #cfcfcf;
          color: $primary-blue-2;
          font-weight: 700;
          white-space: nowrap !important;
        }
      }
    }
  }
}

.footer-3 {
  background-color: $primary-blue-1;
  color: white;
  padding: 24px 32px;

  .items-container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .inner-items-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        flex-grow: 1;
        padding: 1em;
        @media (min-width: 992px) {
          width: 20%;
        }
      }

      .footer-ul {
        margin: auto;
        margin-bottom: 12px;
        list-style-type: none;
        padding-left: 0;

        a {
          color: white;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .footer-left-border {
        border-left: 2px solid white;
        @media (max-width: 990px) {
          border-left: none;
          border-top: 2px solid white;
          padding-top: 1em;
        }
      }
    }
  }
}
