@import './_colors';

.cd-breadcrumb.triangle {
  margin: auto;
  border-radius: 0.25em;
  background-color: transparent;
  display: inline;
}

.cd-breadcrumb.triangle li {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin: 10px 2px;
}

.cd-breadcrumb.triangle li:last-of-type {
  margin-right: 0;
}

.cd-breadcrumb.triangle li > * {
  display: inline-block;
  position: relative;
  text-align: center;
  background-color: $primary-blue-3;
  border-color: $primary-blue-3;
  margin: auto;
  height: 48px;

  a {
    vertical-align: middle;
  }
}

.cd-breadcrumb.triangle li:first-of-type > * {
  border-radius: 0.25em 0 0 0.25em;
}

.cd-breadcrumb.triangle li.current > * {
  color: white;
  background-color: $primary-purple-2;
  border-color: $primary-purple-2;
}

.cd-breadcrumb.triangle li:last-of-type > * {
  border-radius: 0 0.25em 0.25em 0;
}

.cd-breadcrumb.triangle > li > div {
  display: table;
}

.cd-breadcrumb.triangle > li > div > a {
  display: table-cell;
  vertical-align: middle;
  padding: 0 30px;
}

.cd-breadcrumb.triangle div:hover {
  background-color: $primary-purple-2;
  border-color: $primary-purple-2;
}

@media only screen and (min-width: 400px) {
  .cd-breadcrumb.triangle li::after,
  .cd-breadcrumb.triangle li > *::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    /* 48px is the height of the <a> element */
    border: 24px solid transparent;
    border-right-width: 0;
    border-left-width: 25px;
  }

  .cd-breadcrumb.triangle li::after {
    /* this is the white separator between two items */
    z-index: 1;
    -webkit-transform: translateX(4px);
    -moz-transform: translateX(4px);
    -ms-transform: translateX(4px);
    -o-transform: translateX(4px);
    transform: translateX(4px);
    border-left-color: #ffffff;
    margin: 0;
  }
}

.cd-breadcrumb.triangle li > *::after {
  /* this is the colored triangle after each element */
  z-index: 2;
  border-left-color: inherit;
}

.cd-breadcrumb.triangle li:last-of-type::after,
.cd-breadcrumb.triangle li:last-of-type > *::after {
  /* hide the triangle after the last step */
  display: none;
}
