@import '../../css/_colors';

.ColorContainerMain {
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 3em;
  }

  .ColorContainer {
    display: flex;
    flex-direction: row;

    > div {
      height: 3em;
      width: 3em;
      border: 1px solid #000;
    }
  }

  .ColorContainer.Primary.Blue {
    > :nth-child(1) {
      background-color: $primary-blue-1;
    }
    > :nth-child(2) {
      background-color: $primary-blue-2;
    }
    > :nth-child(3) {
      background-color: $primary-blue-3;
    }
    > :nth-child(4) {
      background-color: $primary-blue-4;
    }
    > :nth-child(5) {
      background-color: $primary-blue-5;
    }
    > :nth-child(6) {
      background-color: $primary-blue-6;
    }
  }

  .ColorContainer.Primary.Purple {
    > :nth-child(1) {
      background-color: $primary-purple-1;
    }
    > :nth-child(2) {
      background-color: $primary-purple-2;
    }
    > :nth-child(3) {
      background-color: $primary-purple-3;
    }
    > :nth-child(4) {
      background-color: $primary-purple-4;
    }
  }

  .ColorContainer.Primary.Yellow {
    > :nth-child(1) {
      background-color: $primary-yellow-1;
    }
    > :nth-child(2) {
      background-color: $primary-yellow-2;
    }
    > :nth-child(3) {
      background-color: $primary-yellow-3;
    }
    > :nth-child(4) {
      background-color: $primary-yellow-4;
    }
  }

  .ColorContainer.Secondary.Red {
    > :nth-child(1) {
      background-color: $secondary-red-1;
    }
    > :nth-child(2) {
      background-color: $secondary-red-2;
    }
    > :nth-child(3) {
      background-color: $secondary-red-3;
    }
    > :nth-child(4) {
      background-color: $secondary-red-4;
    }
  }

  .ColorContainer.Secondary.Green {
    > :nth-child(1) {
      background-color: $secondary-green-1;
    }
    > :nth-child(2) {
      background-color: $secondary-green-2;
    }
    > :nth-child(3) {
      background-color: $secondary-green-3;
    }
    > :nth-child(4) {
      background-color: $secondary-green-4;
    }
    > :nth-child(5) {
      background-color: $secondary-green-5;
    }
  }

  .ColorContainer.Text {
    > :nth-child(1) {
      background-color: $text-1;
    }
    > :nth-child(2) {
      background-color: $text-2;
    }
    > :nth-child(3) {
      background-color: $text-3;
    }
    > :nth-child(4) {
      background-color: $text-4;
    }
  }

  .ColorContainer.BorderAccent {
    > :nth-child(1) {
      background-color: $borderaccent-1;
    }
    > :nth-child(2) {
      background-color: $borderaccent-2;
    }
    > :nth-child(3) {
      background-color: $borderaccent-3;
    }
    > :nth-child(4) {
      background-color: $borderaccent-4;
    }
  }

  .ColorContainer.LinkButton {
    > :nth-child(1) {
      background-color: $linkbutton-1;
    }
    > :nth-child(2) {
      background-color: $linkbutton-2;
    }
  }
}

.ButtonContainer {
  > div > button {
    margin: 1em;
  }
}
